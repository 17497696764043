import { gql } from "@apollo/client";

export const ACC_COMPANY_CHANNELS = gql`
  query Acc_getCompanyChannels($companyUuid: String!) {
    acc_getCompanyChannels(companyUUID: $companyUuid) {
      name
      active
    }
  }
`;

export const ACC_SET_COMPANY_EXT_DATA = gql`
  mutation Acc_setCompanyExtData(
    $input: CompanyExtDataInput!
    $companyUuid: String!
  ) {
    acc_setCompanyExtData(input: $input, companyUUID: $companyUuid) {
      code
      success
      message
      data {
        title
        styles {
          favicon
          logoMain
          logoSecond
        }
        styleUrls {
          favicon {
            fileID
            url
            method
            xAmxTagging
          }
          logoMain {
            fileID
            url
            method
            xAmxTagging
          }
          logoSecond {
            fileID
            url
            method
            xAmxTagging
          }
        }
        loyalty {
          used
          description
        }
        metrika {
          yandex
        }
      }
    }
  }
`;

export const ACC_GET_COMPANY_EXT_DATA = gql`
  query Acc_getCompanyExtData($companyUuid: String!) {
    acc_getCompanyExtData(companyUUID: $companyUuid) {
      title
      styles {
        favicon
        logoMain
        logoSecond
      }
      styleUrls {
        favicon {
          fileID
          url
          method
          xAmxTagging
        }
        logoMain {
          fileID
          url
          method
          xAmxTagging
        }
        logoSecond {
          fileID
          url
          method
          xAmxTagging
        }
      }
      loyalty {
        used
        description
      }
      metrika {
        yandex
      }
    }
  }
`;

export const ACC_SEARCH_DNS = gql`
  mutation Acc_searchDnsHostLink($host: String!) {
    acc_searchDnsHostLink(host: $host) {
      code
      success
      message
      data {
        companyUUID
        companyName
        cName
        subDomain
      }
    }
  }
`;
