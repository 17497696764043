import { useLazyQuery } from "@apollo/client";
import { EXPORT_SELF_INFO } from "components/gql/gql_queries_export";
import { GetData } from "contexts/DataContext";
// import { GetAuthData } from "contexts/TAuthContext";
import {
  consoleBeauty,
  isIdentical,
  isObjectEmpty,
  logGQLError,
} from "data/functions";

export function SelfInfoQuery() {
  const queryName = "SelfInfo";
  // const { token } = GetAuthData();
  const { dataObj, forceSetDataObj, setDataObj } = GetData();
  const [refetch, variables] = useLazyQuery(EXPORT_SELF_INFO, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    // skip: !token,
    onCompleted: (data) => {
      onCompletedUser(data, { dataObj, setDataObj });
    },
    onError: (e) => {
      onCompletedUser(undefined, { dataObj, forceSetDataObj, setDataObj });
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}

export function onCompletedUser(
  data,
  { dataObj, forceSetDataObj, setDataObj },
) {
  if (!isObjectEmpty(data?.export_userRead)) {
    if (!isIdentical(data?.export_userRead, dataObj?.clientInfoArray)) {
      consoleBeauty(
        "Запрос информации пользователя по токену. Успех.",
        "GraphQL",
        "darkgreen",
      );
      setDataObj(data?.export_userRead, "clientInfoArray");
      if (
        data?.export_userRead.name.fullName &&
        data?.export_userRead.name.fullName !== null &&
        data?.export_userRead.name.fullName !== dataObj.clientName
      ) {
        setDataObj(data?.export_userRead.name.fullName, "clientName");
      }
      if (
        data?.export_userRead.imageUrls !== null &&
        data?.export_userRead.imageUrls instanceof Array &&
        data?.export_userRead.imageUrls.length > 0
      ) {
        // setDataObj(data?.userRead.phone, "clientPhones");
        setDataObj(data?.export_userRead.imageUrls[0], "clientAvatar");
      }
      if (
        data?.export_userRead.phone !== null &&
        data?.export_userRead.phone instanceof Array &&
        data?.export_userRead.phone.length > 0 &&
        (data?.export_userRead.phone?.toString() !==
          dataObj.clientPhones?.toString() ||
          data?.export_userRead.phone[0] !== dataObj.usedPhone)
      ) {
        // setDataObj(data?.userRead.phone, "clientPhones");
        setDataObj(data?.export_userRead.phone[0], "usedPhone");
      }
    }
  } else {
    forceSetDataObj({}, "clientInfoArray");
    forceSetDataObj("", "clientName");
    forceSetDataObj("", "usedPhone");
  }
  // setDataObj(new Date(Date.now()), "lastDataUpdate");
}
