import { gql } from "@apollo/client";

export const AUTH_SELF_DATA = gql`
  query GetSelfData($token: String) {
    auth_getSelfData(token: $token) {
      companyUUID
      clientID
      payload
      limited
    }
  }
`;

export const AUTH_SESSION_PAYLOAD = gql`
  mutation Auth_extSessionPayload($payload: String!) {
    auth_extSessionPayload(payload: $payload) {
      code
      success
      message
      data {
        accessToken
        expiresIn
      }
    }
  }
`;

export const AUTH_FLOW_INIT = gql`
  query GetExtFlowInit(
    $companyUuid: String!
    $channel: ChannelType!
    $phone: String
  ) {
    auth_getExtFlowInit(
      companyUUID: $companyUuid
      channel: $channel
      phone: $phone
    ) {
      codeLength
      expiresIn
      flowID
      telegramURL
    }
  }
`;

export const AUTH_EXT_LOGIN = gql`
  mutation ExtLogIn($input: ExtLogInInput!) {
    auth_extLogIn(input: $input) {
      code
      success
      message
      data {
        accessToken
        expiresIn
        refreshToken
        refreshExpiresIn
      }
    }
  }
`;

export const AUTH_EXT_LOGOUT = gql`
  mutation ExtLogOut {
    auth_extLogOut {
      code
      success
      message
    }
  }
`;

export const AUTH_EXT_REFRESH_TOKEN = gql`
  mutation Auth_extRefreshToken($input: ExtRefreshTokenInput!) {
    auth_extRefreshToken(input: $input) {
      code
      success
      message
      data {
        accessToken
        expiresIn
        refreshToken
        refreshExpiresIn
      }
    }
  }
`;

export const AUTH_OIDC_LOGIN = gql`
  mutation Auth_oidcLogIn($input: OidcLogInInput!) {
    auth_oidcLogIn(input: $input) {
      code
      success
      message
      data {
        accessToken
        expiresIn
        refreshToken
        refreshExpiresIn
      }
    }
  }
`;
