import { gql } from "@apollo/client";

export const GIBDD_UPLOAD_URL = gql`
  query UploadUrlQuery($filename: String!) {
    gibdd_uploadUrl(filename: $filename) {
      xAmxTagging
      url
      method
      fileID
    }
  }
`;

export const GIBDD_CAR_REPORT = gql`
  query CarReport($grz: String!, $companyUuid: String) {
    gibdd_carReport(GRZ: $grz, CompanyUUID: $companyUuid) {
      vin
      sts
      grz
      brandOriginal
      brandNormalized
      year
      engineFuelType
      enginePowerHp
      enginePowerKw
    }
  }
`;

export const GIBDD_FILE_UPLOAD = gql`
  mutation Gibdd_fileUpload($input: FileUploadInput!) {
    gibdd_fileUpload(input: $input) {
      code
      message
      result {
        fileId
        size
        contentType
      }
      success
    }
  }
`;

export const GIBDD_GRZ_DECODE = gql`
  query Gibdd_grzDecode($fileId: String!) {
    gibdd_grzDecode(fileID: $fileId) {
      grz
      grzs
      logUuid
    }
  }
`;

export const GIBDD_STS_DECODE = gql`
  query Gibdd_stsDecode($fileId: String!, $companyUuid: String) {
    gibdd_stsDecode(fileID: $fileId, CompanyUUID: $companyUuid) {
      brand
      chassis
      color
      grz
      logUuid
      model
      owner_fio
      stsNumber
      trailer
      view
      vin
      year
    }
  }
`;
