import { gql } from "@apollo/client";

export const EXPORT_SELF_INFO = gql`
  query Export_userRead($companyUuid: String!, $userId: String) {
    export_userRead(companyUUID: $companyUuid, userID: $userId) {
      id
      name {
        firstName
        middleName
        lastName
        fullName
      }
      birthday
      email
      phone
      sex
      employee
      imageUrls
      telegram
    }
  }
`;

export const EXPORT_USER_UPDATE = gql`
  mutation Mutation($input: UserUpdateInput!) {
    export_userUpdate(input: $input) {
      success
      message
      code
      user {
        id
        name {
          firstName
          middleName
          lastName
          fullName
        }
        birthday
        email
        phone
        sex
      }
    }
  }
`;

export const EXPORT_COMPANY_DEPARTMENTS = gql`
  query CompanyDepartments($companyUuid: String!) {
    export_companyDepartments(companyUUID: $companyUuid) {
      departments {
        ID
        name
        workTime
        adress
        locationLatitude
        locationLongitude
        phone
        region
        imageUrls
      }
      regions {
        name
      }
    }
  }
`;

export const EXPORT_CAR_LIST = gql`
  query CarsList($userId: String) {
    export_carsList(userID: $userId, includeSold: true) {
      sold
      regNum
      lastVisitAt
      lastMileage
      car {
        id
        color
        marka {
          id
          name
          imageUrl
        }
        model {
          id
          name
          imageUrl
        }
        vin
        year
      }
    }
  }
`;

export const EXPORT_CAR_ADD = gql`
  mutation CarAdd($input: CarAddInput!) {
    export_carAdd(input: $input) {
      code
      success
      message
    }
  }
`;

export const EXPORT_CAR_UPDATE = gql`
  mutation CarUpdate($input: CarUpdateInput!) {
    export_carUpdate(input: $input) {
      car {
        id
        color
        marka {
          id
          name
          imageUrl
        }
        model {
          id
          name
          imageUrl
        }
        vin
        year
      }
      lastMileage
      lastVisitAt
      regNum
      sold
    }
  }
`;

export const EXPORT_UPLOAD_URL = gql`
  query Export_uploadUrl(
    $companyUuid: String!
    $filename: String!
    $prefix: String
  ) {
    export_uploadUrl(
      companyUUID: $companyUuid
      filename: $filename
      prefix: $prefix
    ) {
      fileID
      url
      method
      xAmxTagging
    }
  }
`;

export const EXPORT_REJECT_REASONS = gql`
  query RejectReasons {
    export_rejectReasons {
      id
      name
    }
  }
`;

export const EXPORT_REJECT_RECOMMENDATIONS = gql`
  mutation RecommendationReject($input: Rejection!) {
    export_recommendationReject(input: $input) {
      code
      success
      message
      result
    }
  }
`;

export const EXPORT_REC_LIST = gql`
  query RecommendationsList($carId: String!, $userId: String!) {
    export_recommendationsList(carID: $carId, userID: $userId) {
      id
      text
      urgency
      createdAt
    }
  }
`;

export const EXPORT_SERVICES_LIST = gql`
  query ServicesList(
    $companyUuid: String!
    $categoryId: String
    $serviceId: String
    $recommended: Boolean
  ) {
    export_servicesList(
      companyUUID: $companyUuid
      categoryID: $categoryId
      serviceID: $serviceId
      recommended: $recommended
    ) {
      categories {
        parentId
        id
        name
        hasChildren
        imageUrl
      }
      services {
        parentId
        id
        name
        description
        fullDescription
        imageUrl
      }
      flowId
    }
  }
`;

export const EXPORT_SERVICE_PRICE = gql`
  query ServicePrices(
    $companyUuid: String!
    $markaId: String
    $modelId: String
    $serviceId: [String!]
  ) {
    export_servicePrices(
      companyUUID: $companyUuid
      markaID: $markaId
      modelID: $modelId
      serviceID: $serviceId
    ) {
      price
      priceType
      salePrice
      service {
        id
        name
        description
      }
    }
  }
`;

export const EXPORT_SEARCH_SERVICE = gql`
  query svcSearch(
    $companyUuid: String!
    $searchText: String!
    $flowId: String
  ) {
    export_svcSearch(
      companyUUID: $companyUuid
      searchText: $searchText
      flowID: $flowId
    ) {
      categories {
        parentId
        id
        name
        hasChildren
        imageUrl
      }
      services {
        parentId
        id
        name
        description
        fullDescription
        imageUrl
      }
      flowId
    }
  }
`;

export const EXPORT_ORDERS_LIST = gql`
  query OrdersList {
    export_ordersList {
      departmentID
      description
      docDate
      docNumber
      docSum {
        operations
        parts
        total
      }
      masterName
      mileage
      operations {
        count
        discount
        name
        sum
      }
      orderID
      parts {
        count
        discount
        name
        sum
      }
      status
      type
      planDate
      carID
      payPaid
      payWaiting
      payDebt
      payStatus
    }
  }
`;

export const EXPORT_ORDER_READ = gql`
  query OrderRead($orderId: String!) {
    export_orderRead(orderID: $orderId) {
      carID
      departmentID
      description
      docDate
      docNumber
      docSum {
        operations
        parts
        total
      }
      masterName
      mileage
      operations {
        count
        discount
        name
        sum
      }
      orderID
      parts {
        count
        discount
        name
        sum
      }
      status
      type
      planDate
      payPaid
      payWaiting
      payDebt
      payStatus
    }
  }
`;

export const EXPORT_DEAL_ADD = gql`
  mutation DealAdd($input: DealAdd!) {
    export_dealAdd(input: $input) {
      code
      success
      message
      result {
        dealDate
        dealID
        dealNumber
      }
    }
  }
`;

export const EXPORT_GET_CREDITS = gql`
  query CreditBalance($userId: String) {
    export_creditBalance(userID: $userId) {
      activeCreditsTotal
      credits {
        activeCredit
        inactiveCredit
        lifePeriod {
          expireDate
          activateDate
        }
      }
      inactiveCreditsTotal
      presents {
        name
        lifePeriod {
          expireDate
          activateDate
        }
        id
      }
    }
  }
`;

export const EXPORT_CREDIT_HISTORY = gql`
  query CreditHistory($userId: String, $companyUuid: String) {
    export_creditHistory(userID: $userId, companyUUID: $companyUuid) {
      activeCredits
      date
    }
  }
`;

export const EXPORT_PAYMENT_CREATE = gql`
  mutation Export_paymentCreate($companyUuid: String!, $orderId: String!) {
    export_paymentCreate(companyUUID: $companyUuid, orderID: $orderId) {
      code
      success
      message
      result {
        paymentID
        paymentUrl
      }
    }
  }
`;

export const EXPORT_PAYMENT_CHECK = gql`
  query Export_paymentCheck(
    $paymentId: String!
    $orderId: String
    $userId: String
    $companyUuid: String
  ) {
    export_paymentCheck(
      paymentID: $paymentId
      orderID: $orderId
      userID: $userId
      companyUUID: $companyUuid
    ) {
      code
      message
    }
  }
`;
export const EXPORT_MESSAGE_LIST = gql`
  query Query($limit: Int!, $start: String, $companyUuid: String) {
    chat_messageList(limit: $limit, start: $start, companyUUID: $companyUuid) {
      end
      messages {
        eventID
        direction
        content {
          body
          fileType
          fileID
          fileUrl
          size
          duration
        }
        timestamp
      }
    }
  }
`;

export const EXPORT_MESSAGE_SEND = gql`
  mutation Chat_messageSend($input: MessageSendReq!) {
    chat_messageSend(input: $input) {
      code
      success
      message
      data {
        eventID
      }
    }
  }
`;
