import { Box, LinearProgress, useTheme } from "@mui/material";
import { GetChat } from "contexts/ChatContext";
import {
  dateToString,
  isObjectEmpty,
  parseRFC3339,
  scrollBottom,
} from "data/functions";
import { localeTranslate } from "data/models";
import React, { useEffect, useState } from "react";
import { register } from "timeago.js";
import { ChatBlock } from "./ChatBlock";

export function ChatBody(props) {
  const { messages } = GetChat();

  register("my-locale", localeTranslate);

  useEffect(() => {
    scrollBottom(1);
    // console.log(containerRef.current.getBoundingClientRect().height);
  }, []);

  return (
    <Box
      id="chat_container"
      sx={{ overflow: "auto", width: "100%", position: "relative" }}
    >
      <Box
        // ref={containerRef}

        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "auto",
        }}
      >
        {/* <Box ref={ref} sx={{ height: "1px", width: "100%" }} /> */}
        <Box id="observer_end_block" />
        {/* <Box sx={{ height: "2px" }}> */}

        {[...(messages ?? [])].map((el, ind) => (
          <Box
            endstring={el?.endString}
            key={ind}
            name={ind === messages?.length - 2 ? "lastBlock" : ""}
          >
            <ChatBlock message={el} />
          </Box>
        ))}
        {!isObjectEmpty(messages) && messages?.length > 5 && (
          <ObserverBlock {...props} />
        )}
      </Box>
    </Box>
  );
}

function ObserverBlock(props) {
  useEffect(() => {
    let element = document.getElementById("observer_block");

    let handleIntersection = async function (entries) {
      // Loop through all the observed elements
      for (let entry of entries) {
        // Check if the element is intersecting the viewport
        if (entry.isIntersecting) {
          let chat = document.getElementById("chat_container");
          chat.scrollTop = 10;
          let lastBlock = document.getElementsByName("lastBlock");
          if (lastBlock && lastBlock[0].getAttribute("endstring")) {
            let attr = lastBlock[0].getAttribute("endstring");
            props.getMessages({ end: attr }, 10);
          } else {
            props.getMessages(props?.endMark, 10);
          }

          // console.log(
          //   "The following element is visible in the viewport: ",
          //   entry.target,
          // );
          // ...
        }
      }
    };

    const observer = new IntersectionObserver(handleIntersection);
    setTimeout(() => {
      observer.observe(element);
    }, 1000);
    return () => {
      observer.disconnect();
    };
  }, [props?.endMark]);

  return (
    <React.Fragment>
      <Box id="observer_block" sx={{ height: "2px", width: "100%" }}></Box>
    </React.Fragment>
  );
}
