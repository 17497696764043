import { List } from "@mui/material";
import { CarAddBlock } from "components/CarAddBlock";
import CarListItem from "components/CarListItem";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { isObjectEmpty } from "data/functions";
import CarListTitle from "pages/cars/list/CarListTitle";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

export default function CarList() {
  const { dataObj } = GetData();
  const [carArr, setCarArr] = useState(dataObj?.carArr || []);
  const { cInfo } = GetCInfo();
  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ CarList: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <CarListTitle setCarArr={setCarArr} title="Мои автомобили" />
      <List>
        {!isObjectEmpty(carArr) &&
          carArr.map((el, index) => {
            return (
              <CarListItem el={el} index={index} key={index} navigate={true} />
            );
          })}
        <CarAddBlock />
      </List>
      <Outlet />
    </React.Fragment>
  );
}
