import { Avatar, Box } from "@mui/material";
import Logo from "data/images/logo.svg";
import React from "react";

import { AccountBox, ChatOutlined, ShoppingBasket } from "@mui/icons-material";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { CreditBlock } from "pages/main/mobile/CreditBlock";
import { TopButtonBlock } from "pages/main/mobile/TopButtonBlock";

export function MobileTitle() {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();
  const ProfileLogoComponent = (
    <Avatar
      alt={dataObj?.clientName}
      src={dataObj?.clientAvatar ? dataObj?.clientAvatar : undefined}
      sx={{ width: "1.4em", height: "1.4em" }}
    >
      {dataObj?.clientName ? dataObj?.clientName.charAt(0) : ""}
    </Avatar>
  );
  return (
    <Box
      sx={{
        padding: "12px",
        overflow: "hidden",
        minHeight: "60px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "sticky",
        alignItems: "center",
        zIndex: 3,
        boxShadow: `rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, 
          rgba(0, 0, 0, 0.12) 0px 1px 3px 0px`,
      }}
    >
      <img
        alt="Logo"
        loading="lazy"
        onError={(e) => {
          e.target.src = Logo;
        }}
        src={
          cInfo?.style?.logoMain?.url || cInfo?.style?.logoSecond?.url || Logo
        }
        style={{
          // maxWidth: "45px",
          maxHeight: "46px",
          maxWidth: "100%",
        }}
      />
      {/* <Logotype5S
        style={{
          maxWidth: "45px",
          maxHeight: "45px",
        }}
      /> */}

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <CreditBlock />

        <TopButtonBlock
          badgeContent={dataObj?.currentBasketCount}
          icon={<ShoppingBasket />}
          title="Корзина"
          to="basket"
        />

        {cInfo?.channel !== "telegram" && (
          <TopButtonBlock
            badgeContent={dataObj?.unreadMessages || 0}
            icon={<ChatOutlined />}
            title="Чат"
            to="chat"
          />
        )}

        <TopButtonBlock
          badgeContent={dataObj?.activeOrdersCount}
          icon={ProfileLogoComponent}
          title="Профиль"
          to="profile"
        />
      </Box>
    </Box>
  );
}
