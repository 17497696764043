import { Box } from "@mui/material";
import React from "react";

import { CarLeftSVG } from "data/styleGlobals";

export default function ProfileImage(props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        background: "white",
      }}
    >
      {props?.car?.car?.model?.imageUrl ? (
        <img
          alt={props?.car?.car?.vin}
          loading="lazy"
          onError={(e) => {
            // e.target.hidden = true;
          }}
          src={props?.car?.car?.model?.imageUrl}
          style={{ overflow: "hidden", width: "800px" }}
        />
      ) : (
        <CarLeftSVG
          // color="dimblue"
          style={{
            // height: dataObj?.deviceWidth?.isMobile ? "6rem" : "12rem",
            // width: dataObj?.deviceWidth?.isMobile ? "6rem" : "12rem",
            opacity: "1",
            padding: "12px",
          }}
        />
      )}
    </Box>
  );
}
