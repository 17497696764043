import "@fontsource/open-sans";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React, { useEffect, useState } from "react";

import { CssBaseline } from "@mui/material";
import { ruRU } from "@mui/x-date-pickers/locales";
import { GetData } from "contexts/DataContext";
import {
  getLocalStorageItem,
  isIdentical,
  isObjectEmpty,
} from "data/functions";
import { th } from "date-fns/locale";
import { GetTelegramContext } from "./TelegramContext";

const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

export const GetColorMode = () => {
  const context = React.useContext(ColorModeContext);
  return context;
};

// const tg = window?.Telegram?.WebApp;

const ThemeContext = React.createContext();

function ThemeProvider({ children }) {
  const { dataObj, setDataObj } = GetData();

  const [mode, setMode] = useState();
  const [bgColor, setBgColor] = useState("transparent");
  const [buttonColor, setButtonColor] = useState("#1976d2");
  const [textColor, setTextColor] = useState("#808080");
  const tg = GetTelegramContext();

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) =>
          prevMode === "light" || !prevMode ? "dark" : "light",
        );
      },
    }),
    [],
  );
  // console.log(mode);

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor) =>
    augmentColor({ color: { main: mainColor } });

  const theme = React.useMemo(
    () =>
      createTheme(
        {
          components: {
            MuiBadge: {
              styleOverrides: {
                badge: {
                  fontSize: "0.6rem",
                  height: "14px",
                  minWidth: "14px",
                  width: "14px",
                },
              },
            },
            MuiButton: {
              variants: [
                {
                  props: {
                    color: "tgButton",
                  },
                },
              ],
            },
            MuiCssBaseline: {
              styleOverrides: {
                "*::-webkit-scrollbar": {
                  display: "none",
                  height: "0.6em",
                  width: "0.4em",
                },
                "*::-webkit-scrollbar-thumb": {
                  backgroundColor: "#6b6b6b",
                  borderRadius: "8px",
                  // visibility: "hidden",
                },
                "*::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    backgroundColor: "#959595",
                    // visibility: "visible",
                  },
                "*::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: "#959595",
                  },
                "*::-webkit-scrollbar-thumb:hover": {
                  background: "#aaa",
                },
                "*::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#959595",
                  },
                "*::-webkit-scrollbar-track": {
                  background: "transparent",
                  borderRadius: "8px",
                },
                "*::-webkit-scrollbar:active": {
                  display: "block",
                },
                "*:hover::-webkit-scrollbar:hover": {
                  display: "block",
                },
                scrollbarColor: "#6b6b6b transparent",
              },
              // `
              //   *::-webkit-scrollbar {
              //     width: 0.4em;
              //     height: 0.6em;
              //   }
              //   *::-webkit-scrollbar-track {
              //     background: #FAFAFA;
              //     borde-radius: 8px;
              //   }
              //   *::-webkit-scrollbar-thumb {
              //     background-color: #C8C8C8;
              //     border-radius: 8px;
              //   }
              //   *::-webkit-scrollbar-thumb:hover {
              //     background: '#aaa';
              //   }
              // `,
              // {
              //   body: {
              //     "*": {
              //       "scrollbar-width": "thin",
              //     },
              //     scrollbarColor: "#6b6b6b transparent",
              //   },
              // },
            },
            MuiDrawer: {
              styleOverrides: {
                // paper: {
                //   background: bgColor ? bgColor : undefined,
                // },
                root: {
                  background: bgColor ? bgColor : undefined,
                },
              },
            },
            MuiListItemIcon: {
              styleOverrides: {
                root: {
                  minWidth: 35,
                },
              },
            },
            MuiPaper: {
              styleOverrides: {
                root: {
                  background: bgColor ? bgColor : undefined,
                },
              },
            },
            MuiUseMediaQuery: {
              defaultProps: {
                noSsr: true,
              },
            },
          },
          custom: {
            telegramColors: {
              bg_color: bgColor ? bgColor : undefined,
              button_color: buttonColor ? buttonColor : undefined,
              text_color: textColor,
            },
          },

          palette: {
            background: {
              default: bgColor ? bgColor : undefined,
            },
            dimblue: createColor("#2b74bd"),
            dimgreen: createColor("#378b59"),
            dimgrey: createColor("#434343"),
            mode: mode ? mode : undefined,
            tgButton: buttonColor ? createColor(buttonColor) : undefined,

            dimdarkgreen: createColor("#446b54"),
            dimdarkred: createColor("#5d1616"),
            dimred: createColor("#c32929"),
            white: createColor("#fff"),
          },
          spacing: 2,
          typography: {
            fontFamily: "'Open Sans', sans-serif",
            // In Chinese and Japanese the characters are usually larger,
            // so a smaller fontsize may be appropriate.
            fontSize: 14,
          },
        },
        ruRU,
      ),
    // eslint-disable-next-line
    [mode, bgColor, textColor],
  );

  // tg?.ready(() => {
  //   setTgReady(true);
  // });

  theme?.spacing(2);
  // useEffect(() => {
  //   // eslint-disable-next-line
  // }, [tg?.colorScheme]);

  useEffect(() => {
    if (!isObjectEmpty(tg?.initDataUnsafe)) {
    }
    let newMode =
      tg?.isActive && tg?.data.colorScheme
        ? tg?.data.colorScheme
        : getLocalStorageItem("data_themeMode")
          ? getLocalStorageItem("data_themeMode")
          : window.matchMedia &&
              window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light";
    if (!isIdentical(mode, newMode)) setMode(newMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tg?.isActive, dataObj?.deviceWidth]);

  useEffect(() => {
    if (mode) {
      setDataObj(mode, "themeMode");
      let params = tg?.themeParams;

      if (params?.button_color) {
        setButtonColor(params?.button_color);
      }
      if (params?.text_color) {
        setTextColor(params?.text_color);
      }
      let newBg =
        tg?.themeParams?.bg_color && mode === tg?.data.colorScheme
          ? tg?.themeParams?.bg_color
          : mode === "light"
            ? "#fff"
            : "#161c23";
      if (!isIdentical(bgColor, newBg)) setBgColor(newBg);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MuiThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline enableColorScheme />
        <ThemeContext.Provider value={""}>{children}</ThemeContext.Provider>
        {/* {children} */}
      </MuiThemeProvider>
    </ColorModeContext.Provider>
  );
}

export { ThemeProvider };
