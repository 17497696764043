import { useLazyQuery } from "@apollo/client";
import { GIBDD_STS_DECODE } from "components/gql/gql_queries_gibdd";
import { logGQLError } from "data/functions";

export function GetStsDecode() {
  const queryName = "StsDecode";
  const [refetch, variables] = useLazyQuery(GIBDD_STS_DECODE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}
