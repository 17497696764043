import { getUA, isMobilePlatform, isObjectEmpty } from "data/functions";
import React, { useEffect, useState } from "react";
import { GetData } from "./DataContext";
export const TelegramContext = React.createContext();

function TelegramProvider({ children }) {
  // const [tg, setTg] = useState();
  const [isActive, setIsActive] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { dataObj, setDataObj } = GetData();
  let tg = window?.Telegram?.WebApp;
  let tgVersion = tg?.version;

  useEffect(() => {
    if (
      !isObjectEmpty(tg) &&
      !isObjectEmpty(window?.Telegram?.WebApp?.initDataUnsafe)
    ) {
      tg?.expand();
      if (versionAtLeast(tgVersion, "7.7")) tg?.disableVerticalSwipes();
      if (versionAtLeast(tgVersion, "8.0")) {
        if (isMobilePlatform(getUA())) {
          changeFullscreen("set");
        } else {
          changeFullscreen("exit");
        }
      }
      setIsActive(true);
    }
  }, [tg, tg?.initDataUnsafe]);

  tg?.ready();

  function changeFullscreen(mode = "") {
    async function exit() {
      await tg?.exitFullscreen();
      setIsFullscreen(false);
    }
    async function request() {
      await tg?.requestFullscreen();
      setIsFullscreen(true);
    }
    if (versionAtLeast(tgVersion, "8.0")) {
      if (mode === "set") {
        request();
      } else if (mode === "exit") {
        exit();
      } else {
        if (isFullscreen) {
          exit();
        } else {
          request();
        }
      }
    }
  }

  return (
    <TelegramContext.Provider
      value={{
        data: tg,
        isActive: isActive,
        version: tgVersion,
        isFullscreen: isFullscreen,
        changeFullscreen: changeFullscreen,
      }}
    >
      {children}
    </TelegramContext.Provider>
  );
}

export const GetTelegramContext = () => {
  const context = React.useContext(TelegramContext);
  return context;
};

export { TelegramProvider };

function versionCompare(v1, v2) {
  if (typeof v1 !== "string") v1 = "";
  if (typeof v2 !== "string") v2 = "";
  v1 = v1.replace(/^\s+|\s+$/g, "").split(".");
  v2 = v2.replace(/^\s+|\s+$/g, "").split(".");
  var a = Math.max(v1.length, v2.length),
    i,
    p1,
    p2;
  for (i = 0; i < a; i++) {
    p1 = parseInt(v1[i]) || 0;
    p2 = parseInt(v2[i]) || 0;
    if (p1 === p2) continue;
    if (p1 > p2) return 1;
    return -1;
  }
  return 0;
}

export function versionAtLeast(verNow, ver) {
  return versionCompare(verNow, ver) >= 0;
}
