import { useMutation } from "@apollo/client";
import { EXPORT_MESSAGE_SEND } from "components/gql/gql_queries_export";
import { consoleBeauty, logGQLError } from "data/functions";

export function ExportMessageSendMutation() {
  const queryName = "ExportMessageSend";
  const [mutation, variables] = useMutation(EXPORT_MESSAGE_SEND, {
    onCompleted: (data) => {
      if (data?.chat_messageSend.code === 200) {
        consoleBeauty("Успешная отправка сообщения", "GraphQL", "darkgreen");
      } else {
        logGQLError(queryName, data?.chat_messageSend);
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [mutation, variables];
}
