import { Add } from "@mui/icons-material";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { NotFoundBlock } from "components/NotFoundBlock";
import { PageTitle } from "components/PageTitle";
import { OrdersListQuery } from "components/gql/queris/GetOrdersList";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import {
  isIdentical,
  isObjectEmpty,
  translateOrderStatus,
  translateOrderType,
} from "data/functions";
import { FilterBlock } from "pages/orders/list/FilterBlock";
import { OrderBlock } from "pages/orders/list/OrderBlock";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function OrderList() {
  const { dataObj, setDataObj } = GetData();
  const [arrayOrders, setArrayOrders] = useState([]);
  const [ordersVars] = OrdersListQuery();
  const [filter, setFilter] = useState(dataObj?.orderFilter || {});
  const { yaParams } = GetMetrika();
  const navigate = useNavigate();

  React.useEffect(() => {
    yaParams({ OrderList: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isObjectEmpty(dataObj.orders)) {
      if (!isIdentical(dataObj.orders, arrayOrders)) {
        setArrayOrders(dataObj.orders);
      }
    }
    // eslint-disable-next-line
  }, [dataObj.orders]);

  useEffect(() => {
    setDataObj(filter, "orderFilter");
    // eslint-disable-next-line
  }, [filter]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box
          sx={{ height: dataObj?.deviceWidth?.isMobile ? "100px" : "140px" }}
        >
          <Grid container spacing={0} sx={{ alignItems: "center" }}>
            <Grid item xs={10}>
              <PageTitle alignStart={true} title="Заказы" />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                disableRipple
                onClick={() => {
                  navigate("/request");
                }}
                size="small"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  borderRadius: "200px",

                  //
                }}
              >
                <Tooltip title={"Записаться"}>
                  <Add
                    sx={{
                      borderRadius: "20px",
                    }}
                  />
                </Tooltip>
                {!dataObj?.deviceWidth?.isMobile && (
                  <Typography sx={{ fontSize: "12px" }} variant="body1">
                    {"Записаться"}
                  </Typography>
                )}
              </IconButton>
            </Grid>
          </Grid>

          {/* <CarSlider /> */}
          <FilterBlock filter={filter} setFilter={setFilter} />
          <Box sx={{ height: "2px" }}>
            {ordersVars?.loading && <LinearProgress sx={{ height: "2px" }} />}
          </Box>
          {!ordersVars?.loading && isObjectEmpty(arrayOrders) && (
            <NotFoundBlock />
          )}
        </Box>

        <Box
          sx={{
            overflow: "auto",
          }}
        >
          {!isObjectEmpty(arrayOrders) &&
            arrayOrders.map((order, index) => {
              if (
                (!isObjectEmpty(filter?.type)
                  ? filter?.type === translateOrderType(order?.type)
                  : true) &&
                (!isObjectEmpty(filter?.status)
                  ? filter?.status === translateOrderStatus(order?.status)
                  : true) &&
                (!isObjectEmpty(filter?.pay)
                  ? filter?.pay === translateOrderStatus(order?.payStatus)
                  : true) &&
                (!isObjectEmpty(filter?.car)
                  ? filter?.car?.car?.id === order?.carID
                  : true)
              )
                return (
                  <OrderBlock
                    carID={order?.carID}
                    filter={filter}
                    index={index}
                    item={order}
                    key={index}
                  />
                );
              else return "";
            })}
        </Box>
      </Box>
    </React.Fragment>
  );
}
