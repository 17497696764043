import React, { useState } from "react";

import { ExtLoginMutation } from "components/gql/mutations/ExtLogin";

import { Box, Button, Typography } from "@mui/material";
import { Timer } from "components/Timer";
import { ExtFlowInitQuery } from "components/gql/queris/GetExtFlowInit";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { getFlowFunc, isObjectEmpty } from "data/functions";
import PhoneField from "pages/deal/fields/PhoneField";
import { StatefulPinInput } from "react-input-pin-code";

export const CodeBlock = (props) => {
  const { setAlert } = GetAlertData();
  const { cInfo } = GetCInfo();
  const [remainingTime, setRemainingTime] = useState(30);
  const [timerCount, setTimerCount] = useState();
  const [flowID, setFlowID] = useState("");
  const [active, setActive] = useState(false);
  const [codeLength, setCodeLength] = useState(0);
  const [usedPhone, setUsedPhone] = useState("");
  const [loginRefetch] = ExtLoginMutation();
  const [flowRefetch] = ExtFlowInitQuery();

  async function getFlow(ch, ph = "") {
    if (!ch) return;
    // eslint-disable-next-line no-unused-vars
    let [flowID, expiresIn, telegramURL, length] = await getFlowFunc(
      flowRefetch,
      ch,
      cInfo?.UUID,
      ph,
    );

    if (flowID) {
      setActive(true);
      setTimer(30);
      setFlowID(flowID);
      setCodeLength(length);
      return;
    } else {
      setAlert(
        `Не удалось использовать этот метод авторизации. Попробуйте позже. ${ph ? "\nНомер: " + ph : ""}`,
        "warning",
      );
      return;
    }
  }

  const handleSubmit = () => {
    if (!isObjectEmpty(usedPhone)) {
      getFlow(props?.method, usedPhone.replace(/\D/g, ""));
    }
  };

  async function checkCode(code) {
    loginRefetch({
      variables: {
        input: {
          check_code: code,
          company_uuid: cInfo?.UUID,
          flow_id: flowID,
        },
      },
    });
  }

  function setTimer(secs) {
    setTimerCount(new Date(Date.now() + secs * 1000));
  }

  return (
    <React.Fragment>
      <Box sx={{ padding: "1rem" }}>
        {!active && (
          <>
            <PhoneField
              autoFocus={true}
              setUsedPhone={setUsedPhone}
              usedPhone={usedPhone}
            />

            <Button
              fullWidth
              // loading={props?.flowVars?.loading}
              onClick={() => handleSubmit()}
              sx={{ mt: 3, mb: 2 }}
              type="submit"
              variant="contained"
            >
              Далее
            </Button>
          </>
        )}
        {active && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              margin: "2rem 0px",
            }}
          >
            <Typography
              sx={{ textAlign: "center", paddingBottom: "2rem" }}
              variant="body2"
            >
              {"Для подтверждения введите код, "}
              <br />
              {props?.method === "sms"
                ? "отправленный на телефон "
                : `состоящий из последних ${codeLength} цифр номера входящего звонка`}
            </Typography>
            <StatefulPinInput
              length={codeLength}
              onChange={(el, ind, arr) => {
                if (arr.join("").length >= codeLength) {
                  checkCode(arr.join(""));
                }
              }}
              placeholder=""
              showState={false}
              size="lg"
              // validate={codeCheck}
            />
            <Box sx={{ paddingTop: "1rem" }}>
              <Timer
                expiryTimestamp={timerCount}
                method={props?.method}
                remainingTime={remainingTime}
                setMethod={props?.setMethod}
                setRemainingTime={setRemainingTime}
                variant="code"
              />
            </Box>
            {remainingTime < 1 && (
              <Button
                onClick={() => {
                  handleSubmit();
                }}
                variant="body2"
              >
                {props?.method === "sms"
                  ? "Выслать код повторно"
                  : "Запросить звонок повторно"}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};
