import styled from "@emotion/styled";
import { Badge } from "@mui/material";
import { Box } from "@mui/system";
import { HeaderRow } from "components/HeaderRow";
import { PageTitle } from "components/PageTitle";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { ChangeProfile } from "pages/profile/change/UserChangeDialog";
import { UserMainData } from "pages/profile/info/UserMainData";
import { About as UserSecondData } from "pages/profile/info/UserSecondData";
import React from "react";

const FlexBox = styled(Box)({
  alignItems: "stretch",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  justifyContent: "space-evenly",
});

function Profile(props) {
  const { dataObj } = GetData();

  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ Profile: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle
          display={props.display ? props.display : undefined}
          title="Мой профиль"
        />
        <Box sx={{ overflow: "auto" }}>
          <FlexBox sx={{ overflow: "auto" }}>
            <UserMainData
              fromMain={props?.fromMain}
              open={open}
              setOpen={setOpen}
            />
            <UserSecondData info={dataObj?.clientInfoArray} />
            <HeaderRow navigateOnClick="/cars" text="Автомобили" />
            <HeaderRow
              headerText={
                <Badge
                  // sx={{ minWidth: "20px", height: "20px" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      minWidth: "20px",
                      height: "20px",
                    },
                  }}
                  badgeContent={dataObj?.activeOrdersCount || 0}
                  color="primary"
                />
              }
              navigateOnClick="/orders"
              text="Заказы"
            />
            {dataObj?.includeBeta && (
              <HeaderRow navigateOnClick="/profile" text="Мои отзывы" />
            )}
          </FlexBox>
        </Box>
      </Box>
      <ChangeProfile open={open} setOpen={setOpen} />
    </React.Fragment>
  );
}

export default Profile;
