import { CameraAlt, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { ExportUploadUrlQuery } from "components/gql/queris/ExportUploadUrl";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import React, { useState } from "react";
import { uploadFile } from "tools/requests";

export function ImageField(props) {
  const { cInfo } = GetCInfo();
  const { setAlert } = GetAlertData();
  const inputRef = React.useRef(null);
  const [queryUpload] = ExportUploadUrlQuery();
  const [file, setFile] = useState();

  const handleChange = async function (e) {
    e.preventDefault();
    await changeImageArray(e.target.files);
    e.target.value = null;
    e.stopPropagation();
  };

  async function changeImageArray(array) {
    if (array && array[0] && cInfo?.UUID) {
      for (let i = 0; i < array.length; i++) {
        // showLoading();
        if (array[i]?.type === "application/x-msdownload") {
          setAlert("Исполнительные файлы не разрешены", "warning");
          continue;
        }
        let mb = array[i]?.size / (1024 * 1024);
        if (mb > 30) {
          setAlert("Файлы размером более 30 МБ не разрешены", "warning");
          continue;
        }
        let filename = array[i].name
          .replaceAll(" ", "")
          .replace(/[\u0400-\u04FF]/gi, "_");
        let { data } = await queryUpload({
          variables: {
            adminToken: props?.adminToken,
            companyUuid: cInfo?.UUID,
            filename: filename ? filename : "filename",
            prefix: "style",
          },
        });
        if (data?.export_uploadUrl?.fileID) {
          let success = await uploadFile(data?.export_uploadUrl, array[0]);
          if (!success) {
            setAlert(
              "Не удалось загрузить файл\nПопробуйте позже или перезайдите в управление",
              "warning",
            );
          } else {
            let obj = {
              fileData: array[i],
              fileId: data?.export_uploadUrl?.fileID,
            };
            setFile(obj);
            props?.setAdminData((e) => {
              return {
                ...e,
                [props?.valueName]: data?.export_uploadUrl?.fileID,
              };
            });
          }
          //There wll be a func upload file
        }
      }
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        padding: "0.4rem 0 ",
      }}
    >
      <Typography sx={{ fontWeight: "500" }} variant="body1">
        {props?.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px 6px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "45%",
          }}
        >
          <img
            alt={props?.valueName}
            loading="lazy"
            // onError={(e) => {
            //   e.target.src = Logo;
            // }}
            src={props?.startData}
            style={{
              // maxWidth: "45px",
              height: props?.valueName === "favicon" ? "25px" : "50px",
              width: props?.valueName === "favicon" ? "25px" : undefined,
              padding: "2px",
            }}
          />
          {/* <Typography variant="caption">Текущая</Typography> */}
        </Box>
        <KeyboardDoubleArrowRight />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "45%",
          }}
        >
          <input
            accept={
              props?.valueName === "favicon"
                ? "image/x-icon, image/png, image/gif, image/jpeg"
                : "image/png, image/gif, image/jpeg"
            }
            alt={props?.valueName}
            id={`input-file-upload-${props?.valueName}`}
            multiple={false}
            onChange={handleChange}
            ref={inputRef}
            style={{ display: "none" }}
            type="file"
          />
          <label
            htmlFor={`input-file-upload-${props?.valueName}`}
            id={`label-file-upload-${props?.valueName}`}
            style={{
              border: "1px dashed rgba(143, 143, 143, 0.245)",
              padding: "2px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props?.value &&
            file?.fileData &&
            file?.fileData?.type.includes("image") ? (
              <img
                alt={`New ${props?.valueName}`}
                loading="lazy"
                src={window.URL.createObjectURL(file?.fileData)}
                style={{
                  // maxWidth: "45px",
                  height: props?.valueName === "favicon" ? "25px" : "50px",
                  width: props?.valueName === "favicon" ? "25px" : "100%",
                }}
              />
            ) : (
              <CameraAlt sx={{ height: "25px", width: "25px" }} />
            )}
          </label>

          {/* <Typography variant="caption">Новая</Typography> */}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
