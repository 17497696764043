import { useMutation } from "@apollo/client";
import { ACC_SET_COMPANY_EXT_DATA } from "components/gql/gql_queries_acc";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty, logGQLError } from "data/functions";

export function SetCompanyExtDataMutation() {
  const queryName = "UpdateCompanyStyle";
  const { setAlert } = GetAlertData();

  const [mutation, variables] = useMutation(ACC_SET_COMPANY_EXT_DATA, {
    onCompleted: (data) => {
      if (data?.acc_setCompanyExtData?.success) {
        consoleBeauty(
          "Успешное обновление внешних данных компании",
          "GraphQL",
          "darkgreen",
        );
        setAlert("Данные сохранены", "success");
      } else {
        setAlert("Не удалось сохранить новые данные", "error");
        logGQLError(queryName, data?.acc_setCompanyExtData);
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
      setAlert("Не удалось сохранить новые данные", "error");
    },
  });

  return [mutation, variables];
}
