export const gqlReducer = (state, action) => {
  switch (action.type) {
    case "EXECUTE": {
      action.function(action.variables);
      return state;
    }
    case "USER_UPD": {
      return { ...state, needToUpdateUser: action.param };
    }
    default:
      return state;
  }
};

export const constants = {
  ruCANCELED: "Отменен",
  ruCOMPLETED: "Закрыт",
  ruIN_WORK: "В работе",
  ruNONE: "",
  ruPAID: "Оплачен",
  ruPAY_WAITING: "Ожидание оплаты",
  ruREADY: "Выполнен",
  ruTypeBP: "Заявка",
  ruTypeLID: "Предварительная заявка",
  ruTypeR: "Заказ товаров",
  ruTypeZN: "Заказ-наряд",
  ruTypeZP: "Заказ товаров",
  ruTypeZR: "Предварительная запись",
};

export const actions = {
  checkArray: "CHECK_ARRAY",
  clearArray: "CLEAR_ARRAY",
  clearArrayParam: "CLEAR_ARRAY_PARAM",
  deleteArray: "DELETE_ARRAY",
  forceSet: "FORCE_SET",
  pushArray: "PUSH_ARRAY",
  replaceArray: "REPLACE_ARRAY",
  resetCache: "RESET_CACHE",
  setArray: "SET_ARRAY",
};
