import { useMutation } from "@apollo/client";

import { EXPORT_CAR_ADD } from "components/gql/gql_queries_export";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty, logGQLError } from "data/functions";

export function AddCarMutation() {
  const queryName = "AddCar";
  const { setAlert } = GetAlertData();
  const [mutation, variables] = useMutation(EXPORT_CAR_ADD, {
    onCompleted: (data) => {
      if (data?.export_carAdd?.code === 200) {
        consoleBeauty("Успешно добавлен автомобиль", "GraphQL", "darkgreen");
        setAlert("Автомобиль добавлен!");
      } else {
        setAlert("AddCar: Не удалось добавить автомобиль", "warning");
        logGQLError(queryName, data?.export_carAdd);
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
      setAlert("Не добавить автомобиль. \nПопробуйте позже.", "error");
    },
  });
  return [mutation, variables];
}
