import { useLazyQuery } from "@apollo/client";
import { EXCHANGE_GET_MODEL } from "components/gql/gql_queries_exchange";
import { consoleBeauty, logGQLError } from "data/functions";

export function GetModelQuery() {
  const queryName = "GetModel";
  const [refetch, variables] = useLazyQuery(EXCHANGE_GET_MODEL, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: () => {
      consoleBeauty("Запрос данных модели. Успех.", "GraphQL", "darkgreen");
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}
