import { useLazyQuery } from "@apollo/client";

import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import {
  consoleBeauty,
  isIdentical,
  isObjectEmpty,
  logGQLError,
} from "data/functions";
import { EXPORT_CAR_LIST } from "../gql_queries_export";

export function CarListQuery() {
  const queryName = "CarList";
  const { token } = GetAuthData();
  const { dataObj, forceSetDataObj, setDataObj } = GetData();
  const [carRefetch, carVars] = useLazyQuery(EXPORT_CAR_LIST, {
    fetchPolicy: "no-cache",
    skip: !token,
    // notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      onCompletedCarList(data, { dataObj, forceSetDataObj, setDataObj });
    },
    onError: (e) => {
      onCompletedCarList(undefined, { dataObj, forceSetDataObj, setDataObj });
      logGQLError(queryName, e);
    },
  });
  return [carRefetch, carVars];
}

export function onCompletedCarList(
  data,
  { dataObj, forceSetDataObj, setDataObj },
) {
  let newArr = (
    !isObjectEmpty(data?.export_carsList) ? data?.export_carsList : []
  ).sort((a, b) => {
    let dateA = new Date(a?.lastVisitAt || 0);
    let dateB = new Date(b?.lastVisitAt || 0);
    if (dateA < dateB) return 1;
    else return -1;
  });
  newArr = newArr.map((el, ind) => {
    el.regNum = el.regNum.toLowerCase();
    return el;
  });

  if (isObjectEmpty(newArr)) {
    forceSetDataObj([], "carArr");
    forceSetDataObj({}, "usedCar");
    forceSetDataObj([], "carArrWithSold");
    forceSetDataObj([], "carIds");
    forceSetDataObj(0, "carsCount");
    if (!data) forceSetDataObj(true, "carsError");
  } else if (!isIdentical(newArr, dataObj?.carArrWithSold)) {
    consoleBeauty("Получен список машин", "GraphQL", "darkgreen");
    let carListWithoutSold = [];
    let carIds = [];
    newArr.forEach((element) => {
      if (element.sold === false) {
        carIds = [...carIds, element?.car?.id];
        carListWithoutSold = [...carListWithoutSold, element];
      }
    });
    if (!isIdentical(dataObj?.carIds, carIds)) setDataObj(carIds, "carIds");
    setDataObj(carListWithoutSold, "carArr");
    if (!dataObj.usedCar) {
      setDataObj(carListWithoutSold[0], "usedCar");
    } else {
      let find = carListWithoutSold.find((el) => {
        if (el?.car?.id === dataObj.usedCar?.car?.id) {
          return true;
        } else return false;
      });
      if (!isObjectEmpty(find)) setDataObj(find, "usedCar");
    }
    // setDataObj(new Date(Date.now()), "lastDataUpdate");
    setDataObj(newArr, "carArrWithSold");
    forceSetDataObj(carIds.length, "carsCount");
    forceSetDataObj(false, "carsError");
  }
}
