import { gql } from "@apollo/client";

export const EXCHANGE_GET_MARK = gql`
  query Exch_getMark($markId: String!) {
    exch_getMark(markID: $markId) {
      id
      name
      shortName
      objectId
      presignURL {
        fileID
        method
        url
        xAmxTagging
      }
    }
  }
`;

export const EXCHANGE_GET_MARKS = gql`
  query getMarks($orderBy: MarksOrderBy) {
    exch_getMarks(orderBy: $orderBy, size: 9999, page: 1) {
      marks {
        id
        name
        shortName
      }
    }
  }
`;

export const EXCHANGE_SEARCH_MARK = gql`
  query Exch_searchMark($markName: String!) {
    exch_searchMark(markName: $markName) {
      id
      name
      shortName
      objectId
      priority
      presignURL {
        fileID
        method
        url
        xAmxTagging
      }
    }
  }
`;

export const EXCHANGE_GET_MODEL = gql`
  query Exch_getModel($markId: String!, $modelId: String!) {
    exch_getModel(markID: $markId, modelID: $modelId) {
      id
      markId
      name
      synonyms
      presignURL {
        fileID
        method
        url
        xAmxTagging
      }
    }
  }
`;

export const EXCHANGE_GET_MODELS = gql`
  query getModels($markId: String!) {
    exch_getModels(markID: $markId, size: 9999, page: 1) {
      models {
        id
        name
        yearFrom
        yearTo
        markId
        synonyms
      }
    }
  }
`;

export const EXCHANGE_SEARCH_MODEL = gql`
  query Exch_searchModel($markId: String!, $modelName: String!) {
    exch_searchModel(markID: $markId, modelName: $modelName) {
      id
      name
      yearFrom
      yearTo
      markId
      synonyms
      presignURL {
        fileID
        method
        url
        xAmxTagging
      }
    }
  }
`;

export const EXCHANGE_DECODE_VIN = gql`
  mutation Exch_decodeVin($vin: String!) {
    exch_decodeVin(vin: $vin) {
      code
      success
      message
      data {
        manufacturers {
          id
          name
          shortName
        }
        models {
          id
          name
          yearFrom
          yearTo
          markId
        }
      }
    }
  }
`;
