import { useLazyQuery } from "@apollo/client";
import { GIBDD_CAR_REPORT } from "components/gql/gql_queries_gibdd";
import { consoleBeauty, logGQLError } from "data/functions";

export function GetCarReportQuery() {
  const queryName = "CarReport";
  const [refetch, variables] = useLazyQuery(GIBDD_CAR_REPORT, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: () => {
      consoleBeauty(
        "Получен ответ по расшифровке ГИБДД",
        "GraphQL",
        "darkgreen",
      );
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}
