import { Box } from "@mui/material";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { showMainScreen } from "data/mobapp_functions";
import { DesktopMainPage } from "pages/main/desktop/DesktopMain";
import { MobileMainPage } from "pages/main/mobile/MobileMain";
import React from "react";

function MainPage() {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();
  const { yaParams } = GetMetrika();
  React.useEffect(() => {
    if (dataObj?.clientID) showMainScreen(dataObj?.clientID);
    yaParams({ Main: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {dataObj?.deviceWidth?.isMobile ? (
        <MobileMainPage />
      ) : (
        <DesktopMainPage />
      )}
    </React.Fragment>
  );
}

export default MainPage;
