import React from "react";
import { MetrikaCounter, ym } from "react-metrika";
import { GetCInfo } from "./CompanyContext";
export const MetrikaContext = React.createContext();

function MetrikaProvider({ children }) {
  const { cInfo } = GetCInfo();

  const yaParams = React.useCallback(
    (obj) => {
      if (cInfo?.metrika?.yandex)
        ym(Number(cInfo?.metrika?.yandex), "params", obj);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [cInfo?.metrika?.yandex],
  );

  const yaExtLink = React.useCallback(
    (link) => {
      if (cInfo?.metrika?.yandex)
        ym(Number(cInfo?.metrika?.yandex), "extLink", link);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [cInfo?.metrika?.yandex],
  );

  const yaUserID = React.useCallback(
    (clientId) => {
      if (cInfo?.metrika?.yandex)
        ym(Number(cInfo?.metrika?.yandex), "userParams", {
          UserID: clientId,
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [cInfo?.metrika?.yandex],
  );

  const contextValue = React.useMemo(
    () => ({
      yaExtLink,
      yaParams,
      yaUserID,
    }),
    // eslint-disable-next-line
    [yaExtLink, yaParams, yaUserID],
  );

  return (
    <MetrikaContext.Provider value={contextValue}>
      {cInfo?.metrika?.yandex && (
        <MetrikaCounter
          id={Number(cInfo?.metrika?.yandex)}
          options={{
            trackHash: true,
            // webvisor: true,
          }}
        />
      )}

      {children}
    </MetrikaContext.Provider>
  );
}
export const GetMetrika = () => {
  const context = React.useContext(MetrikaContext);
  return context;
};

export { MetrikaProvider };
