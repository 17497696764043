import { useLazyQuery } from "@apollo/client";
import { ACC_GET_COMPANY_EXT_DATA } from "components/gql/gql_queries_acc";
import { logGQLError } from "data/functions";

export function GetCompanyExtDataQuery() {
  const queryName = "GetCompanyStyle";
  const [refetch, variables] = useLazyQuery(ACC_GET_COMPANY_EXT_DATA, {
    // errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}
