import { useLazyQuery } from "@apollo/client";
import { EXPORT_MESSAGE_LIST } from "components/gql/gql_queries_export";

import { GetAuthData } from "contexts/TAuthContext";
import { logGQLError } from "data/functions";

export function ExportMessageListQuery() {
  const queryName = "ExportMessageList";
  const { token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(EXPORT_MESSAGE_LIST, {
    fetchPolicy: "no-cache",
    skip: !token,
    onError: (e) => {
      logGQLError(queryName, e);
      // setLogined(false);
    },
  });
  return [refetch, variables];
}
