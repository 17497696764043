import {
  Box,
  Button,
  ButtonBase,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import {
  findImageForItem,
  getCarName,
  getImageUrlByName,
  getService,
  getServiceParent,
  isIdentical,
  isObjectEmpty,
} from "data/functions";
import React, { useState } from "react";

import { Delete, MoreHoriz } from "@mui/icons-material";
import { GetServices } from "components/gql/queris/GetServices";
import { GetCInfo } from "contexts/CompanyContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ServiceTitle from "../list/ServiceTitle";

export default function ServiceProfile(props) {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();
  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ ServiceProfile: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let location = useLocation();
  const params = useParams();
  const serviceID = location.state?.serviceID || params.id;
  const [getServicesQuery] = GetServices();
  const [item, setItem] = useState(
    (dataObj?.serviceList ?? []).find((el) => el?.id === serviceID),
  );

  React.useEffect(() => {
    findItem();
    // eslint-disable-next-line
  }, [dataObj?.serviceList, cInfo?.UUID]);

  async function findItem() {
    let val = (dataObj?.serviceList ?? []).find((el) => el?.id === serviceID);
    if (isObjectEmpty(val)) {
      val = (cInfo?.pinnedServices ?? []).find((el) => el?.id === serviceID);
    }

    if (isObjectEmpty(val)) {
      val = await getService(cInfo?.UUID, serviceID, getServicesQuery);
    }

    if (val && val?.id && !isIdentical(val, item)) {
      setItem(val);
      // if (val?.parentId) {
      //   let parent = await getServiceParent(
      //     cInfo?.UUID,
      //     val?.parentId,
      //     getServicesQuery,
      //   );
      //   if (parent) setItemParent(parent);
      // }
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <ServiceTitle home={false} />
        {item?.name && (
          <Typography sx={{ padding: "1rem" }} variant="h4">
            {item?.name}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <ServiceLeftSide item={item} />
          <ServiceRightSide item={item} />
        </Box>
      </Box>
    </React.Fragment>
  );
}

function ServiceLeftSide(props) {
  const { dataObj } = GetData();
  return (
    <Box sx={{ width: "100%", overflow: "auto", position: "relative" }}>
      <ServiceImage item={props?.item} />
      <Box sx={{ padding: dataObj?.deviceWidth?.isMobile ? "1rem" : "24px" }}>
        <Typography sx={{ whiteSpace: "pre-line" }} variant="body1">
          {props?.item?.fullDescription}
        </Typography>
      </Box>
    </Box>
  );
}

function ServiceImage(props) {
  const { dataObj } = GetData();

  return (
    <React.Fragment>
      {findImageForItem(props?.item, dataObj?.folderList) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: dataObj?.deviceWidth?.isMobile ? "100%" : "350px",
              padding: "4px",
              aspectRatio: "1 / 1",
              // maxHeight: "200px",

              background: `url(${findImageForItem(
                props?.item,
                dataObj?.folderList,
              )}) center center / 100% no-repeat local padding-box padding-box rgb(255, 255, 255)`,
            }}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

function ServiceRightSide(props) {
  const { dataObj } = GetData();
  const { basket, getPrice, inBasket, prices, sections } = GetBasket();
  const [price, setPrice] = useState(0);
  const [sale, setSale] = useState(0);
  const [priceType, setPriceType] = useState("");
  const [added, setAdded] = useState(false);

  React.useEffect(() => {
    if (!isObjectEmpty(props?.item)) {
      setAdded(inBasket(props?.item, sections.services));
      setItemPrice(props?.item);
    }
    // eslint-disable-next-line
  }, [basket, props?.item, prices]);

  function setItemPrice(element) {
    let carID = dataObj?.usedCar?.car?.id || "all";
    let fSalePrice = 0;
    let fPrice = 0;
    let type = "";
    let priceObj = getPrice(element?.id, carID);
    if (priceObj) {
      fSalePrice = fSalePrice + priceObj?.salePrice;
      fPrice = fPrice + priceObj?.price;
      type = priceObj?.priceType;
    }
    if (!type && fSalePrice === 0) type = "empty_price";
    setPriceType(type);
    setPrice(fSalePrice);
    setSale(fPrice - fSalePrice);
  }

  const paperStyle = {
    alignItems: "center",
    display: "flex",
    flexDirection: dataObj?.deviceWidth?.isMobile ? "row-reverse" : "column",
    justifyContent: dataObj?.deviceWidth?.isMobile
      ? "space-around"
      : "flex-start",
    padding: dataObj?.deviceWidth?.isMobile ? "12px" : "24px",
    width: dataObj?.deviceWidth?.isMobile ? "100%" : "33%",
  };

  const boxStyle = {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: dataObj?.deviceWidth?.isMobile ? undefined : "12px",
    width: dataObj?.deviceWidth?.isMobile ? undefined : "100%",
  };

  return (
    <Paper sx={paperStyle}>
      <BasketButton added={added} item={props?.item} />
      <Box sx={boxStyle}>
        <BasketPrice price={price} priceType={priceType} />
        <BasketSale sale={sale} />
        <BasketPriceInfo car={dataObj?.usedCar} />
      </Box>
    </Paper>
  );
}

function BasketButton({ added, item }) {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  const { inBasket, sectionItemAdd, sectionItemDelete, sections } = GetBasket();

  function handleChecked(value) {
    if (!value) return;
    let find = inBasket(value, sections.services);
    if (!find) sectionItemAdd("basket", value, sections.services);
    else sectionItemDelete("basket", value, sections.services);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  return (
    <React.Fragment>
      {added ? (
        <React.Fragment>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              width: dataObj?.deviceWidth?.isMobile ? "50%" : "100%",
              alignItems: "stretch",
              justifyContent: "center",
            }}
          >
            <Button
              color="dimgrey"
              // disabled={isEmptyContext("registration")}
              fullWidth={dataObj?.deviceWidth?.isMobile ? false : true}
              onClick={() => navigate("/basket")}
              size={dataObj?.deviceWidth?.isMobile ? "small" : "large"}
              sx={{
                textTransform: "none",
                maxHeight: dataObj?.deviceWidth?.isMobile ? "40px" : undefined,
                whiteSpace: "break-spaces",
              }}
              variant="contained"
            >
              Перейти в корзину
            </Button>
            <IconButton
              color="dimgrey"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              sx={{ padding: "2px", width: "24px" }}
            >
              <MoreHoriz />
            </IconButton>
          </Box>
        </React.Fragment>
      ) : (
        <Button
          // disabled={isEmptyContext("registration")}
          fullWidth={dataObj?.deviceWidth?.isMobile ? false : true}
          onClick={() => handleChecked(item)}
          size={dataObj?.deviceWidth?.isMobile ? "small" : "large"}
          sx={{
            textTransform: "none",
            maxHeight: dataObj?.deviceWidth?.isMobile ? "40px" : undefined,
            whiteSpace: "break-spaces",
          }}
          variant="contained"
        >
          Добавить в корзину
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: dataObj?.deviceWidth?.isMobile ? -10 : "bottom",
        }}
        id="demo-customized-menu"
        onClose={() => setAnchorEl(null)}
        open={open}
        transformOrigin={{
          horizontal: "right",
          vertical: dataObj?.deviceWidth?.isMobile ? "bottom" : "top",
        }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            setAnchorEl(null);
            handleChecked(item);
          }}
          sx={{ justifyContent: "flex-end", paddingTop: 0, paddingBottom: 0 }}
        >
          Удалить из корзины
          <Delete />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

function BasketPrice({ price, priceType }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 12px",
        gap: "20px",
      }}
    >
      {priceType === "empty_price" ? (
        <Typography sx={{ textAlign: "right" }} variant="h6">
          Цена по запросу
        </Typography>
      ) : (
        <React.Fragment>
          <Typography sx={{ textAlign: "right" }} variant="h6">
            Цена:
          </Typography>

          <Typography
            sx={{ textAlign: "right", textWrap: "nowrap" }}
            variant="h6"
          >
            {priceType === "min_price"
              ? "от " + price + " руб."
              : price + " руб."}
          </Typography>
        </React.Fragment>
      )}
    </Box>
  );
}

function BasketSale({ sale }) {
  return (
    <React.Fragment>
      {sale > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 12px",
            gap: "20px",
          }}
        >
          <Typography sx={{ textAlign: "right" }} variant="body1">
            Скидка:
          </Typography>
          <Typography
            color="error"
            sx={{ textAlign: "right", textWrap: "nowrap" }}
            variant="body1"
          >
            - {sale} руб.
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
}

function BasketPriceInfo({ car }) {
  return (
    <Typography
      sx={{
        textAlign: "right",
        textWrap: "wrap",
        padding: "0px 12px",
        opacity: "0.4",
      }}
      variant="caption"
    >
      {!isObjectEmpty(car) ? "для " + getCarName(car) : ""}
    </Typography>
  );
}
