import { useLazyQuery } from "@apollo/client";
import { AUTH_FLOW_INIT } from "components/gql/gql_queries_auth";
import { consoleBeauty, logGQLError } from "data/functions";

export function ExtFlowInitQuery() {
  const queryName = "ExtFlowInit";
  const [refetch, variables] = useLazyQuery(AUTH_FLOW_INIT, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: () => {
      consoleBeauty("Запрос авторизации. Успех.", "GraphQL", "darkgreen");
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}
