//https://yandex.ru/dev/maps/jsbox/2.1/placemark/
//https://yandex.ru/dev/maps/jsbox/2.1/balloon_html/
// https://yandex.ru/dev/maps/jsbox/2.1/list_box_layout

import { Box, FormControl, MenuItem, Paper, Select } from "@mui/material";
import { YMaps, withYMaps } from "@pbe/react-yandex-maps";
import { GetCInfo } from "contexts/CompanyContext";
import { isIdentical, isObjectEmpty } from "data/functions";
import * as React from "react";
import { useNavigate } from "react-router-dom";
export function MapBlock(props) {
  const [ok, setOK] = React.useState(false);
  const { cInfo, setCInfo } = GetCInfo();
  const [city, setCity] = React.useState();
  const [departmentArray, setDepartmentArray] = React.useState(
    cInfo?.departments || [],
  );
  const handleChange = (event) => {
    setCity(event.target.value);
  };

  React.useEffect(() => {
    if (!isIdentical(departmentArray, cInfo?.departments)) {
      setDepartmentArray(cInfo?.departments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.departments]);

  React.useEffect(() => {
    if (!isIdentical(city, cInfo?.regions) && !isObjectEmpty(cInfo?.regions)) {
      if (
        !isObjectEmpty(cInfo?.preferedRegion) &&
        cInfo?.regions.find((el) => el?.name === cInfo?.preferedRegion?.name)
      ) {
        setCity(cInfo?.preferedRegion?.name);
      } else {
        setCity(cInfo?.regions[0]?.name);
        // setCInfo(cInfo?.regions[0], "preferedRegion");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.regions, cInfo?.preferedRegion]);

  const mapComp = React.useMemo(() => {
    return ({ setOK, ymaps }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [map, setMap] = React.useState(null);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const mapRef = React.useRef(null);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (!ymaps || !mapRef.current) {
          return;
        }
        ymaps?.ready(() => {
          let dep = (departmentArray ?? []).find((element) => {
            if (element?.region === city) return true;
            else return false;
          });
          if (isObjectEmpty(dep)) {
            dep = [...(departmentArray ?? [])];
          }
          if (isObjectEmpty(dep)) {
            dep = [
              {
                // 43.345068, 42.465897
                locationLatitude: 43.345068,
                locationLongitude: 42.465897,
              },
            ];
          }
          // if (!mapRef.current?.innerHTML) {
          if (isObjectEmpty(mapRef.current?.children)) {
            let mapN = new ymaps.Map(
              mapRef.current,
              {
                center: [dep[0]?.locationLatitude, dep[0]?.locationLongitude],
                controls: ["zoomControl"],
                // type: "yandex#hybrid",
                zoom: 10,
              },
              {
                geolocationControlFloat: "right",
                // restrictMapArea: true,
                suppressMapOpenBlock: true,
                zoomControlPosition: { left: 10, top: 50 },
                zoomControlSize: "small",
              },
            );
            setMap(mapN);
          }
        });

        // return () => (canceled = true);
      }, [ymaps]);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (map) {
          if (!isObjectEmpty(departmentArray)) {
            departmentArray.forEach((element) => {
              if (!element?.locationLatitude || !element?.locationLongitude) {
                return;
              }
              let BalloonContentLayout =
                ymaps.templateLayoutFactory.createClass(
                  `<div>
                                  <p style="margin: 0 0 5px; font-size: 120%; font-weight: 700;">${element.name}</p>
                                  <p style="margin: 0 0 5px; font-size: 100%;" >${element.adress}</p>
                                  <button id="map-link-button" style="background-color: transparent;border: 1px solid rgba(25, 118, 210, 0.5);color: #1976d2;">Показать</button>
                              </div>`,
                  {
                    // Переопределяем функцию build, чтобы при создании макета начинать
                    // слушать событие click на кнопке-счетчике.
                    build: function () {
                      // Сначала вызываем метод build родительского класса.
                      BalloonContentLayout.superclass.build.call(this);
                      // А затем выполняем дополнительные действия.
                      let button =
                        window.document.getElementById("map-link-button");
                      button.addEventListener("click", this.funcN);
                      // button.bind('click', this.funcN);
                    },

                    // Аналогично переопределяем функцию clear, чтобы снять
                    // прослушивание клика при удалении макета с карты.
                    clear: function () {
                      // Выполняем действия в обратном порядке - сначала снимаем слушателя,
                      // а потом вызываем метод clear родительского класса.
                      let button =
                        window.document.getElementById("map-link-button");
                      // button.unbind('click', this.funcN);.
                      button.removeEventListener("click", this.funcN);
                      BalloonContentLayout.superclass.clear.call(this);
                    },

                    funcN: function () {
                      // console.log("test");
                      navigate(`/departments/${element.ID}`);
                    },
                  },
                );
              let placemark = new ymaps.Placemark(
                [element?.locationLatitude, element?.locationLongitude],
                {
                  balloonContent: element.adress,
                  // balloonContentFooter: `<button id="map-link-button">Показать</button>`, //`<a href="/departments/${element.ID}">Показать</>`
                  balloonContentHeader: element.name,

                  iconContent: "",
                },
                {
                  balloonAutoPan: false,
                  balloonCloseButton: true,
                  balloonContentLayout: BalloonContentLayout,
                  // balloonPanelMaxMapArea: 0,
                  openBalloonOnClick: true,
                },
              );
              if (element?.region === city || isObjectEmpty(city)) {
                map.geoObjects.add(placemark);
              }

              // if (departmentArray.length === 1) placemark.balloon.open();
            });
            if (map.geoObjects.getBounds()) {
              map
                .setBounds(map.geoObjects.getBounds(), { checkZoomRange: true })
                .then(function () {
                  if (map.getZoom() > 15) map.setZoom(15);
                });
            }
          } else {
            let placemark = new ymaps.Placemark(
              [43.352465, 42.437889],
              {
                balloonContentHeader: "гора Эльбрус",
                iconContent: "",
              },
              {
                balloonAutoPan: false,
                balloonCloseButton: true,
                openBalloonOnClick: true,
              },
            );
            map.geoObjects.add(placemark);
            if (map.geoObjects.getBounds()) {
              map
                .setBounds(map.geoObjects.getBounds(), {
                  checkZoomRange: true,
                })
                .then(function () {
                  if (map.getZoom() > 12) map.setZoom(12);
                });
            }
          }

          if (map.controls.get("zoomControl")) {
            map.controls.add("zoomControl", {
              float: "none",
              position: {
                bottom: "50px",
                right: "30px",
              },
              size: "small",
            });
            setOK(true);
          }
          let zoom = map.controls.get("zoomControl");
          zoom.events.add("click", function (e) {
            if (!map.behaviors.isEnabled("drag")) {
              map.behaviors.enable("drag");
            }
          });
          if (map.behaviors.isEnabled("scrollZoom")) {
            map.behaviors.disable("scrollZoom");
          }
          if (map.behaviors.isEnabled("drag")) {
            map.behaviors.disable("drag");
          }
          if (map.behaviors.isEnabled("dblClickZoom")) {
            map.behaviors.disable("dblClickZoom");
          }
          if (map.behaviors.isEnabled("multiTouch")) {
            map.behaviors.disable("multiTouch");
          }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [map, city]);

      return (
        <div
          ref={mapRef}
          style={{
            width: "98%",
            height: "240px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, departmentArray]);

  const Map = React.useMemo(() => {
    return withYMaps(mapComp, true, ["Map", "Placemark"]);
  }, [mapComp]);

  // function haveBaloon(array) {
  //   if (isObjectEmpty(array)) return false;
  //   let finded = false;
  //    departmentArray.forEach((element) => {
  //     if (!isObjectEmpty(element?.locationLatitude) && !isObjectEmpty(element?.locationLongitude)) {
  //       finded = true;
  //     }
  //   })
  //   return finded;
  // }

  return (
    <React.Fragment>
      {/* {!isObjectEmpty(departmentArray) && haveBaloon(departmentArray) && ( */}
      <Box sx={{ position: "relative" }}>
        <YMaps
          onError={(e) => console.log(e)}
          preload={true}
          query={{
            apikey: "814fa36a-a896-4ffa-971d-1cd05a72aa65",
            load: "package.full",
          }}
        >
          <Map setOK={setOK} />
        </YMaps>
        {ok && (cInfo?.regions ?? []).length > 1 && (
          <Paper sx={{ position: "absolute", top: 2, left: 2 }}>
            <FormControl size="small" sx={{ minWidth: 120 }} variant="standard">
              <Select
                disableUnderline={true}
                id="select-city"
                // label="Город"
                labelId="select-city-label"
                onChange={handleChange}
                sx={{ padding: "4px 0px 0px 12px" }}
                // size="small"
                value={city}
                // variant="standard"
              >
                {!isObjectEmpty(cInfo?.regions) &&
                  (cInfo?.regions ?? []).map((el, ind) => (
                    <MenuItem
                      key={ind}
                      onClick={(e) => {
                        setCInfo(el, "preferedRegion");
                      }}
                      value={el?.name}
                    >
                      {el?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Paper>
        )}
      </Box>
      {/* )} */}
    </React.Fragment>
  );
}
