import { useLazyQuery } from "@apollo/client";
import { EXPORT_SERVICES_LIST } from "components/gql/gql_queries_export";
import { logGQLError } from "data/functions";

export function GetServices() {
  const queryName = "CategoryElements";
  const [refetch, variables] = useLazyQuery(EXPORT_SERVICES_LIST, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}
