import { useTheme } from "@emotion/react";
import { Box, Checkbox, Chip, Typography } from "@mui/material";
import { GetPrices } from "components/gql/queris/GetPrices";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import {
  getElementsPrices,
  getImageUrlByName,
  isObjectEmpty,
} from "data/functions";
import { ServiceBlock } from "pages/services/list/ServiceBlock";
import React, { useState } from "react";
import { getServiceArrayByMode } from "./FindBlock";

export default function ServiceList(props) {
  const { dataObj, setDataObj } = GetData();
  const theme = useTheme();
  const { cInfo } = GetCInfo();
  const { getPrice, inBasket, sectionItemAdd, sectionItemDelete, sections } =
    GetBasket();
  const [pricesQuery] = GetPrices();

  const [serviceArr, setServiceArr] = useState(
    props?.searchMode
      ? dataObj?.serviceSearch?.services ?? []
      : getServiceArrayByMode(dataObj?.serviceList, props?.parent?.id),
  );

  React.useEffect(() => {
    setServiceArr(
      props?.searchMode
        ? dataObj?.serviceSearch?.services ?? []
        : getServiceArrayByMode(dataObj?.serviceList, props?.parent?.id),
    );
  }, [
    props?.searchMode,
    dataObj?.serviceSearch?.services,
    dataObj?.serviceList,
    props?.parent?.id,
  ]);

  React.useEffect(() => {
    props?.setNoServices(isObjectEmpty(serviceArr));
    if (!isObjectEmpty(serviceArr) && cInfo?.UUID) {
      getElementsPrices(
        pricesQuery,
        serviceArr,
        {
          dataObj,
          setDataObj,
        },
        { cInfo },
        { sectionItemAdd },
      );
    }
  }, [serviceArr, dataObj?.usedCar]);

  function handleChecked(value) {
    let find = inBasket(value, sections.services);
    if (!find) sectionItemAdd("basket", value, sections.services);
    else sectionItemDelete("basket", value, sections.services);
  }

  // React.useEffect(() => {
  //   props?.setNoServices(isObjectEmpty(serviceArr));
  // }, [serviceArr]);

  return (
    <React.Fragment>
      <Box
        sx={{
          // padding: "0.4rem",
          marginTop: "-16px",
          display: "inline-flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: dataObj?.deviceWidth?.isMobile ? "100%" : "100%",
          alignItems: dataObj?.deviceWidth?.isMobile ? undefined : "stretch",
        }}
      >
        {serviceArr.map((value, index) => {
          let carID = dataObj?.usedCar?.car?.id;
          let priceObj = getPrice(value?.id, carID);
          let price = priceObj?.price || 0;
          let salePrice = priceObj?.salePrice || 0;
          let subPrice = priceObj?.priceType === "min_price" ? "от " : "";
          const labelId = `checkbox-list-label-${value}`;
          let check = inBasket(value, sections.services);

          const CheckboxComponent = (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                // background: theme?.palette?.background?.default,
                // opacity: "0.5",
              }}
            >
              <Checkbox
                checked={check || false}
                disableRipple
                edge="start"
                inputProps={{ "aria-labelledby": labelId }}
                size="small"
                sx={{
                  padding: "4px",
                  margin: "0",
                  zIndex: 2,
                  "& .MuiSvgIcon-root": {
                    color: theme?.custom?.telegramColors?.button_color,
                  },
                }}
                tabIndex={-1}
              />

              {check && (
                <Chip
                  color={"dimgreen"}
                  label={"В корзине"}
                  sx={{
                    height: "auto",
                    // minHeight: "16px",
                    // maxWidth: "80px",
                    // marginTop: "-12px",
                    margin: "0  8px",
                    padding: "2px",
                    "& .MuiChip-label": {
                      display: "block",
                      textAlign: "center",
                      // whiteSpace: "normal",
                      fontSize: "10px",
                      lineHeight: "1.1",
                    },
                  }}
                />
              )}
            </Box>
          );

          const PriceComponent = price && (
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {subPrice + salePrice + " руб. "}
                <strike>{price > salePrice ? price + " руб." : ""}</strike>
              </Box>
            </React.Fragment>
          );

          return (
            <Box
              key={index}
              sx={{
                // flexGrow: "1",
                width: dataObj?.deviceWidth?.isMobile
                  ? "49%"
                  : dataObj?.deviceWidth?.isPad
                    ? "24%"
                    : "19%",
              }}
            >
              <ServiceBlock
                check={check}
                description={value?.description}
                iconElement={CheckboxComponent}
                image={
                  value?.imageUrl
                    ? value?.imageUrl
                    : getImageUrlByName(value.name)
                }
                item={value}
                price={PriceComponent || ""}
                setItem={handleChecked}
                thereIsEl={true}
                title={value.name}
              />
            </Box>
          );
        })}
      </Box>
    </React.Fragment>
  );
}
