import { useMutation } from "@apollo/client";

import { EXPORT_DEAL_ADD } from "components/gql/gql_queries_export";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty, logGQLError } from "data/functions";

export function NewDealMutation() {
  const queryName = "dealAdd";
  const { setAlert } = GetAlertData();

  const [mutation, variables] = useMutation(EXPORT_DEAL_ADD, {
    onCompleted: (data) => {
      if (data?.export_dealAdd?.code === 200) {
        consoleBeauty("Заявка создана", "GraphQL", "darkgreen");
        setAlert("Заявка успешно создана!", "success");
      } else {
        setAlert("Не удалось создать заявку", "warning");
        logGQLError(queryName, data?.export_dealAdd);
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
      setAlert("Не удалось создать заявку. \nПопробуйте позже.", "error");
    },
  });
  return [mutation, variables];
}
