import { Alert, Box, keyframes } from "@mui/material";
import {
  getLocalStorageItem,
  isIdentical,
  setLocalStorageItem,
} from "data/functions";
import { isObjectEmpty } from "data/functions";
import React from "react";
import { v4 as uuidv4 } from "uuid";
export const AlertContext = React.createContext();

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

function AlertProvider({ children }) {
  const [alertArray, setAlertArray] = React.useState([]);
  const [isMobile, setIsMobile] = React.useState(true);

  const setAlert = React.useCallback((value, param, secs = 3) => {
    let uuid = uuidv4();
    let alert = { id: uuid, text: value, type: param ?? "info" };
    setAlertArray((e) => [...e, alert]);
    setLocalStorageItem("lastAlert", alert);
    setTimeout(() => {
      setAlertArray((e) => e.filter((v) => v?.id !== uuid));
    }, secs * 1000);
  }, []);

  const clear = React.useCallback(() => {
    setAlertArray([]);
  }, []);

  const contextValue = React.useMemo(
    () => ({
      alert,
      clear,
      setAlert,
    }),
    // eslint-disable-next-line
    [alert, setAlert],
  );

  React.useEffect(() => {
    let mobileParam = getLocalStorageItem("data_deviceWidth");
    if (
      !isObjectEmpty(mobileParam) &&
      !isIdentical(mobileParam?.isMobile, isMobile)
    ) {
      setIsMobile(mobileParam?.isMobile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocalStorageItem("data_deviceWidth")]);

  return (
    <AlertContext.Provider value={contextValue}>
      <Box
        sx={{
          position: "fixed",
          background: "transparent",
          zIndex: 2000,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          width: "100%",
          // left: isMobile ? "0" : "256px",
        }}
      >
        {!isObjectEmpty(alertArray) &&
          (alertArray ? alertArray : [])
            .reverse()
            .slice(0, 3)
            .map((el, ind) => (
              <Alert
                icon={false}
                key={ind}
                onClose={() => {
                  setAlertArray((e) => e.filter((v) => v?.id !== el?.id));
                }}
                severity={el?.type}
                sx={{
                  whiteSpace: "pre-line",
                  textAlign: "center",
                  left: isMobile ? "0" : "256px",
                  width:
                    isMobile || window.location.pathname.includes("/auth")
                      ? "100%"
                      : "calc(100% - 256px)",
                  // left: isMobile ? "0" : "256px",
                  justifyContent: "center",
                  position: "sticky",
                  zIndex: 2000,
                  margin: "2px",
                  animation: `${appear} .4s ease-in-out`,
                  top: "0",
                  ".MuiAlert-message": {
                    marginRight: "auto",
                    marginLeft: "auto",
                  },
                  ".MuiAlert-action": {
                    marginLeft: "unset",
                  },
                }}
                variant="filled"
              >
                {el?.text}
              </Alert>
            ))}
      </Box>

      {children}
    </AlertContext.Provider>
  );
}
export const GetAlertData = () => {
  const context = React.useContext(AlertContext);
  return context;
};

export { AlertProvider };
