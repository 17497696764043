import { Checkbox, FormControlLabel } from "@mui/material";
import { GetModelsQuery } from "components/gql/queris/GetModels";
import { GetData } from "contexts/DataContext";
import { findInArray, isIdentical } from "data/functions";
import SelectBlock from "pages/cars/add/SelectBlock";
import FieldBlock from "pages/cars/add/fields/FieldBlock";
import React from "react";

export default function FieldModels(props) {
  const { dataObj } = GetData();
  // const [modelName, setModelName] = React.useState(
  //   props?.carAddData?.modelName,
  // );
  const [notFind, setNotFind] = React.useState(false);
  const [modelsQuery, modelsVars] = GetModelsQuery();

  // React.useEffect(() => {
  //   if (!isIdentical(model, props?.carAddData?.model)) {
  //     setModel(props?.carAddData?.model);
  //     setModelName(props?.carAddData?.model?.name);
  //   }
  // }, [props?.carAddData?.model]);

  React.useEffect(() => {
    props?.setCarAddData((e) => {
      return { ...e, model: null };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notFind]);

  async function getModelsLocal() {
    let res = await getModels(
      dataObj,
      modelsQuery,
      props?.carAddData?.mark?.id,
    );
    if (!isIdentical(res ?? [], props?.carAddData?.modelList)) {
      props?.setCarAddData((e) => {
        return { ...e, model: {}, modelList: res ?? [] };
      });
    }
  }

  function changeModel(value) {
    if (!isIdentical(value, props?.carAddData?.model)) {
      props?.setCarAddData((e) => {
        return { ...e, model: value };
      });
    }
  }

  return (
    <React.Fragment>
      <SelectBlock
        array={props?.carAddData?.modelList}
        customOptionLabelParam={"synonyms"}
        disabled={notFind}
        helperText={
          props?.helperText?.modelText
            ? props?.helperText?.modelText
            : undefined
        }
        label="model"
        loading={modelsVars?.loading}
        onClear={() => {
          getModelsLocal();
        }}
        required={props?.required && !notFind ? props?.required : undefined}
        // setInputValue={setModelName}
        setValue={changeModel}
        title="Модель"
        value={
          !notFind && props?.carAddData?.model ? props?.carAddData?.model : null
        }
      />

      <FormControlLabel
        control={
          <Checkbox checked={notFind} onChange={() => setNotFind((e) => !e)} />
        }
        label="Моей модели нет в списке"
        sx={{ margin: "0rem 1rem" }}
      />
      {notFind && (
        <FieldBlock
          // component={
          //   <ResponseButtonWithDialog
          //     data2={vinData}
          //     dialogTitle2="Расшифровка VIN"
          //     data={fileData}
          //     dialogTitle="Свидетельство о регистрации ТС"
          //   />
          // }
          label="modelText"
          // length={24}
          // onBlurFunc={decodeVin}
          // required={notFind || false}
          setValue={(value) => {
            props?.setCarAddData((e) => {
              return { ...e, model: { id: "", name: value.toUpperCase() } };
            });
          }}
          title="Наименование модели"
          // value={vin}
        />
      )}
    </React.Fragment>
  );
}

export async function getModels(dataObj, modelsQuery, markID, modelID = "") {
  if (!markID) return [];
  let marka = findInArray(dataObj?.markList ?? [], markID, "id");
  if (!marka) {
    return [];
  }
  let { data, error } = await modelsQuery({
    variables: { markId: markID },
  });
  if (data) {
    let modelsArray = data?.exch_getModels?.models || [];
    if (modelsArray.lenght > 1)
      modelsArray.sort((a, b) => {
        return a?.name > b?.name ? 1 : a?.name === b?.name ? 0 : -1;
      });
    return modelsArray;
  }
  if (error) {
    return [];
  }
  return;
}
