import { Box, keyframes, useTheme } from "@mui/material";
// import { GetChat } from "contexts/ChatContext";
import { GetData } from "contexts/DataContext";
import { dateToString, parseRFC3339 } from "data/functions";

import React, { useEffect, useState } from "react";
import { MessageBox } from "react-chat-elements";
import ImageViewer from "react-simple-image-viewer";
export const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export function ChatBlock(props) {
  const [URL, setURL] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [dialogType, setDialogType] = useState();
  const { dataObj } = GetData();
  const theme = useTheme();

  const openImageViewer = React.useCallback((url, type) => {
    setURL([url]);
    setIsViewerOpen(true);
    setDialogType(type);
  }, []);

  async function openFunc(message) {
    let type = parseMessageType(props?.message?.content?.fileType);
    let data = parseMessageData(props?.message?.content);
    if (type === "photo") {
      openImageViewer(data?.uri, "image");
    }
    if (type === "file") {
      let a = document.createElement("a");
      a.target = "_blank";
      a.download = message?.content?.body;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", message.content.fileUrl, true); // Делаем запрос
      xhr.responseType = "blob"; // Указываем, что ожидаем Blob
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onload = function () {
        if (this.status === 200) {
          // Если всё прошло успешно
          var blob = this.response;
          var file = window.URL.createObjectURL(blob);
          a.href = file;
          a.click();
          a.remove();
          // Обрабатываем полученный blob
        }
      };
      xhr.onerror = function (e) {
        console.error(e);
        a.href = data?.uri;
        a.click();
        a.remove();
      };

      xhr.send();
    }
  }

  return (
    <Box
      sx={{
        animation: `${appear} .4s ease-in-out`,
        "& .rce-container-mbox": {
          ".rce-mbox": {
            background: `${theme?.palette?.background?.paper}`,
          },
          ".rce-mbox-left-notch": {
            fill: `${theme?.palette?.background?.paper ?? "transparent"} !important`,
          },
          ".rce-mbox-right": {
            background: `${theme?.palette?.background?.default ?? "transparent"} !important`,
          },
          ".rce-mbox-right-notch": {
            fill: `${theme?.palette?.background?.default ?? "transparent"} !important`,
          },
        },
      }}
    >
      <MessageBox
        data={parseMessageData(props?.message?.content)}
        date={parseRFC3339(props?.message?.timestamp)}
        dateString={parseMessageTime(props?.message?.timestamp, true)}
        onOpen={() => openFunc(props?.message)}
        position={parseMessageDirection(props?.message?.direction)}
        // title="Burhan"
        // reply={el?.reply}
        // replyButton={true}
        text={parseMessageText(
          props?.message?.content?.fileType,
          props?.message?.content?.body,
        )}
        type={parseMessageType(props?.message?.content?.fileType)}
        // width={300}
      />
      {isViewerOpen && (
        <Box
          onClick={() => setIsViewerOpen(false)}
          onTouchEnd={() => setIsViewerOpen(false)}
        >
          {dialogType === "image" && (
            <ImageViewer
              backgroundStyle={{
                width: dataObj?.deviceWidth?.isMobile
                  ? "100%"
                  : "calc(100% - 256px)",
                left: dataObj?.deviceWidth?.isMobile ? "0" : "256px",
                backgroundColor: "rgba(0,0,0,0.5)",
              }}
              closeComponent={<></>}
              closeOnClickOutside={true}
              disableScroll={true}
              onClose={() => setIsViewerOpen(false)}
              src={URL}
              style={{
                color: "black",
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

function parseMessageType(type) {
  switch (type) {
    case "AUDIO":
      return "audio";
    case "DOC":
      return "file";
    case "FILE":
      return "file";
    case "IMAGE":
      return "photo";
    case "SYSTEM":
      return "system";
    case "VIDEO":
      return "video";
    default:
      return "text";
  }
}

function parseMessageText(type, text) {
  let pType = parseMessageType(type);
  switch (pType) {
    case "audio":
    case "photo":
    case "video":
      return "";
    default:
      return text;
  }
}

function parseMessageDirection(str) {
  switch (str) {
    case "FROMCLIENT":
      return "right";
    case "FROMCOMPANY":
      return "left";
    default:
      return "left";
  }
}

function parseMessageTime(str, hours = false) {
  if (hours) return dateToString(parseRFC3339(str), true, true);
  return dateToString(parseRFC3339(str), true);
}

function parseMessageData(el) {
  let obj = {
    id: el?.fileID,
    status: {
      click: true,
      download: true,
    },
  };
  switch (parseMessageType(el?.fileType)) {
    case "audio":
      obj["audioURL"] = el?.fileUrl;
      break;
    case "file":
      obj["uri"] = el?.fileUrl;
      break;
    case "photo":
      obj["uri"] = el?.fileUrl;
      break;
    case "video":
      obj["videoURL"] = el?.fileUrl;
      break;
    default: {
      break;
    }
  }
  return obj;
}
