import { Box, LinearProgress } from "@mui/material";
import { CarRecsQuery } from "components/gql/queris/GetCarRecs";
import { GetCInfo } from "contexts/CompanyContext";
import { GetMetrika } from "contexts/MetrikaContext";
import RecList from "pages/recs/RecList";
import RecsTitle from "pages/recs/RecsTitle";
import React from "react";

export default function Recs(props) {
  const [recVars] = CarRecsQuery();
  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ Recs: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <RecsTitle />
        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ height: "2px" }}>
            {recVars?.loading && <LinearProgress sx={{ height: "2px" }} />}
          </Box>
          <RecList />
          {/* <RecButtons /> */}
        </Box>
      </Box>
    </React.Fragment>
  );
}
